exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-catering-jsx": () => import("./../../../src/pages/Catering.jsx" /* webpackChunkName: "component---src-pages-catering-jsx" */),
  "component---src-pages-cottage-jsx": () => import("./../../../src/pages/Cottage.jsx" /* webpackChunkName: "component---src-pages-cottage-jsx" */),
  "component---src-pages-double-room-balcony-jsx": () => import("./../../../src/pages/DoubleRoomBalcony.jsx" /* webpackChunkName: "component---src-pages-double-room-balcony-jsx" */),
  "component---src-pages-double-room-jsx": () => import("./../../../src/pages/DoubleRoom.jsx" /* webpackChunkName: "component---src-pages-double-room-jsx" */),
  "component---src-pages-gifts-jsx": () => import("./../../../src/pages/Gifts.jsx" /* webpackChunkName: "component---src-pages-gifts-jsx" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-subpages-accommodation-jsx": () => import("./../../../src/pages/subpages/Accommodation.jsx" /* webpackChunkName: "component---src-pages-subpages-accommodation-jsx" */),
  "component---src-pages-subpages-contact-jsx": () => import("./../../../src/pages/subpages/Contact.jsx" /* webpackChunkName: "component---src-pages-subpages-contact-jsx" */),
  "component---src-pages-subpages-intro-jsx": () => import("./../../../src/pages/subpages/Intro.jsx" /* webpackChunkName: "component---src-pages-subpages-intro-jsx" */),
  "component---src-pages-subpages-news-jsx": () => import("./../../../src/pages/subpages/News.jsx" /* webpackChunkName: "component---src-pages-subpages-news-jsx" */),
  "component---src-pages-subpages-restaurant-jsx": () => import("./../../../src/pages/subpages/Restaurant.jsx" /* webpackChunkName: "component---src-pages-subpages-restaurant-jsx" */),
  "component---src-pages-subpages-reviews-jsx": () => import("./../../../src/pages/subpages/Reviews.jsx" /* webpackChunkName: "component---src-pages-subpages-reviews-jsx" */),
  "component---src-pages-subpages-services-jsx": () => import("./../../../src/pages/subpages/Services.jsx" /* webpackChunkName: "component---src-pages-subpages-services-jsx" */),
  "component---src-pages-subpages-surroundings-jsx": () => import("./../../../src/pages/subpages/Surroundings.jsx" /* webpackChunkName: "component---src-pages-subpages-surroundings-jsx" */),
  "component---src-pages-vouchers-jsx": () => import("./../../../src/pages/Vouchers.jsx" /* webpackChunkName: "component---src-pages-vouchers-jsx" */)
}

